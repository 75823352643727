import React, { useEffect, useState } from 'react';
import {
  getEntry,
  getRichTextValue,
} from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import HyperLink from 'components/Core/HyperLink';
import { MarketingProThankYouHeroContainer, ThankYouHeroContainer } from './styles';

const MarketingProThankYouHero = ({ entries }) => {
  const title = getRichTextValue(getEntry('title', entries));
  const redirectUrl = getRichTextValue(getEntry('redirect-url', entries));

  const [countdownTime, setCoundownTime] = useState(5);
  let interval;

  useEffect(() => {
    interval = setInterval(() => {
      setCoundownTime((countdownTime) => (countdownTime > 0 ? countdownTime - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdownTime === 0) {
      clearInterval(interval);
      window.open(redirectUrl, '_blank');
    }
  }, [countdownTime]);

  return (
    <ThankYouHeroContainer>
      <div className="section-thankyou-hero--wrapper">
        <div className={'section-thankyou-hero--container demo-redirect'}>
          <MarketingProThankYouHeroContainer>
            <div className="thankyou-redirect-hero--inner-container">
              <h2 className="section-thankyou-hero--title">{title}</h2>
              <p className="section-thankyou-hero--description">
                Link opens in a new window.
                <br /> <br /> (Please{' '}
                <HyperLink href={redirectUrl} target="_blank">
                  click here
                </HyperLink>{' '}
                if you are not being redirected automatically.)
              </p>
            </div>
            <p className="thankyou-redirect-hero--countdown">{countdownTime}</p>
          </MarketingProThankYouHeroContainer>
        </div>
      </div>
    </ThankYouHeroContainer>
  );
};

export default MarketingProThankYouHero;
