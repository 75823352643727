import styled from 'styled-components';

const MarketingProThankYouHeroContainer = styled.div`
  position: relative;
  height: 280px;

  .thankyou-redirect-hero--inner-container {
    z-index: 2;
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
  }

  .thankyou-redirect-hero--countdown {
    color: var(--blue-3);
    font-weight: var(--font-weight-400);
    font-size: 400px;
    position: absolute;
    right: 0;
    text-align: center;
    bottom: 0;
    width: max-content;
    top: 0;
    left: 0;
    margin: 0 auto;
    line-height: 327px;
  }

  @media (max-width: 575px) {
    .thankyou-redirect-hero--countdown {
      font-size: 350px;
    }
  }
`;

const ThankYouHeroContainer = styled.div`
  background: white;

  .section-thankyou-hero--wrapper {
    background: var(--titan-blue-3);
    background-repeat: no-repeat;
    background-size: 5000px 728px;
  }

  .section-thankyou-hero--container {
    margin: 0 auto;
    padding: 240px 28px 204px;

    .section-thankyou-hero--icon-wrapper {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      svg {
        width: 55px;
        transform: scale(1.3);
      }
    }

    &.demo-redirect {
      .section-thankyou-hero--description {
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-20);
        line-height: var(--line-height-150);
        text-align: center;
        color: var(--white);
        max-width: 784px;
        margin: 40px auto 0px;
      }
      a {
        color: var(--white) !important;
        text-decoration: underline;
      }
    }

    .section-thankyou-hero--title {
      font-weight: var(--font-weight-900);
      font-size: var(--font-size-44);
      line-height: var(--line-height-110);
      text-align: center;
      letter-spacing: var(--letter-spacing--0-05);
      color: var(--white);
      max-width: 1195px;
      margin: 46px auto 0;
    }

    .section-thankyou-hero--subtitle {
      p {
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-20);
        line-height: var(--line-height-150);
        text-align: center;
        color: var(--white);
        max-width: 784px;
        margin: 25px auto 0;
      }

      a {
        color: var(--white) !important;
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 1450px) {
    .section-thankyou-hero--container {
      padding: 180px 28px 204px;
    }
  }

  @media (max-width: 901px) {
    .section-thankyou-hero--wrapper {
      height: 530px;
    }

    .section-thankyou-hero--container {
      padding: 120px 28px 48px;

      .section-thankyou-hero--icon-wrapper {
        width: 56px;
        height: 56px;

        svg {
          transform: scale(1);
        }
      }

      .section-thankyou-hero--title {
        font-size: var(--font-size-28);
        letter-spacing: var(--letter-spacing--0-03);
        margin-top: 40px;
      }

      .section-thankyou-hero--subtitle {
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-16);
        line-height: var(--line-height-150);
        margin: 16px auto 0 auto;
        max-width: 576px;
      }
    }
  }

  @media (max-width: 575px) {
    .section-thankyou-hero--container {
      padding: 90px 28px 48px;
    }

    .section-thankyou-hero--wrapper {
      height: 650px;
    }
  }
`;

export { MarketingProThankYouHeroContainer, ThankYouHeroContainer };
